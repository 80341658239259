import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { ExternalLink } from '../../theme'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'
// import one_off from '../../assets/footbar/1_off.png'
import one_on from '../../assets/footbar/1_on.png'
// import two_off from '../../assets/footbar/2_off.png'
import two_on from '../../assets/footbar/2_on.png'
// import three_off from '../../assets/footbar/3_off.png'
import three_on from '../../assets/footbar/3_on.png'
// import four_off from '../../assets/footbar/4_off.png'
import four_on from '../../assets/footbar/4_on.png'

const FooterWrapper = styled.div`
  display: flex;
  width:100%;
  align-items: center;
  height:72px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  background:${({ theme }) => theme.bg8};
  @media only screen and (min-width: 961px) {
    display:none;
  }
`
const FooterItem = styled.div`
  display: flex;
  width:25%;
  flex-direction: column;
  align-items: center;
`
const ItemImg = styled.img`
  width:28px;
  height:28px;
`
const ItemTxt = styled.span`
  font-size:14px;
  margin-top:5px;
`
const activeClassName = 'ACTIVE'
const StyledNavLink = styled(NavLink).attrs({
    activeClassName
  })`
    ${({ theme }) => theme.flexRowNoWrap}
    display: flex;
    width:100%;
    flex-direction: column;
    align-items: center;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.text4};
    font-weight: 500;
  
    &.${activeClassName} {
      border-radius: 12px;
      font-weight: bold;
      color: ${({ theme }) => theme.text1};
    }
  `
// @ts-ignore
const StyledExternalLink = styled(ExternalLink).attrs({
    activeClassName
  }) < { isActive?: boolean } > `
    ${({ theme }) => theme.flexRowNoWrap}
    display: flex;
    width:100%;
    flex-direction: column;
    align-items: center;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.text2};
    font-weight: 500;
  
    &.${activeClassName} {
      border-radius: 12px;
      font-weight: bold;
      color: ${({ theme }) => theme.text1};
    }
  
    :hover,
    :focus {
      color: ${({ theme }) => darken(0.1, theme.text1)};
      text-decoration: none;
    }
  
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
      margin: 0 8px;
    `}
  `


export default function Footer(){
    const { t } = useTranslation()
    return (
        <FooterWrapper>
            <FooterItem>
                <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
                    <ItemImg src={one_on} />
                    <ItemTxt>{t('tabbarjy')}</ItemTxt>
                </StyledNavLink>
            </FooterItem>
            <FooterItem>
                <StyledNavLink id={`swap-nav-link`} to={'/pool'}>
                    <ItemImg src={four_on} />
                    <ItemTxt>{t('pool')}</ItemTxt>
                </StyledNavLink>
            </FooterItem>
            <FooterItem>
                <StyledNavLink id={`swap-nav-link`} to={'/finds'}>
                    <ItemImg src={three_on} />
                    <ItemTxt>{t('tabbarfx')}</ItemTxt>
                </StyledNavLink>
            </FooterItem>
            <FooterItem>
                <StyledExternalLink id={`stake-nav-link`} href={"https://ktobridge.web3s.finance/"}>
                    <ItemImg src={two_on} />
                    <ItemTxt>{t('tabbarklq')}</ItemTxt>
                </StyledExternalLink>
            </FooterItem>
        </FooterWrapper>
    )
}
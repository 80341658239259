import React, {useEffect,useState} from 'react'
import styled from 'styled-components'
import banner from '../../assets/findimgs/banner.png';
import { ExternalLink } from '../../theme'
import { useTranslation } from 'react-i18next'
import { darken } from 'polished'

const FindWrapper = styled.div`
  width:100%;
`
const BannerImg = styled.img`
  width:100%;
  display:block;
  margin-bottom:20px;
  @media only screen and (min-width: 961px) {
    display:none;
  }
`
const FindMain = styled.div`
  padding:20px 80px 0;
  display:block;
  @media only screen and (max-width: 960px) {
    padding:0 0px;
  }
`
const FindTitle = styled.div`
  width:100%;
  padding:2rem;
  margin-top:10px;
  font-size:18px;
  font-weight:600;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 6px 14px 6px;
  `};
`
const FindList = styled.div`
  width:100%;
  display:flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 960px) {
    padding:10px 16px 0;
  }
`
const FindDappList = styled.div`
  width:100%;
  @media only screen and (min-width: 960px) {
    display:flex;
    flex-wrap: wrap;
    padding:0 30px;
  }
`
const activeClassName = 'ACTIVE'
// @ts-ignore
const StyledExternalLink = styled(ExternalLink).attrs({
    activeClassName
  }) < { isActive?: boolean } > `
    ${({ theme }) => theme.flexRowNoWrap}
    display: flex;
    width:12%;
    flex-direction: column;
    align-items: center;
    margin-bottom:15px;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.text2};
    font-weight: 500;
    background:rgba(255,255,255,.1);
    padding:14px 0;
    border-radius:8px;
    margin-right:2%;
    @media only screen and (max-width: 960px) {
        width:22%;
        padding:10px 0;
        margin-right:3%;
    }
    &.${activeClassName} {
      border-radius: 12px;
      font-weight: bold;
      color: ${({ theme }) => theme.text1};
    }
  
    :hover,
    :focus {
      color: ${({ theme }) => darken(0.1, theme.text1)};
    }
  
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    //   margin: 0 8px;
    `}
  `
const ItemImg = styled.img`
  width:40px;
  height:40px;
  border-radius: 50%;
`
const ItemTxt = styled.span`
  font-size:12px;
  margin-top:8px;
  font-weight:600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding:0 6px;
  width:100%;
  text-align:center;
  color: ${({ theme }) => theme.text1};
`
const StyledGrowExternalLink = styled(ExternalLink).attrs({
    activeClassName
  }) < { isActive?: boolean } > `
    ${({ theme }) => theme.flexRowNoWrap}
    display: flex;
    width:100%;
    align-items: center;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.text2};
    font-weight: 500;
    @media only screen and (max-width: 960px) {
        ${({ theme }) => theme.mediaWidth.upToSmall`
            padding: 10px 15px 10px;
            border-bottom:1px dashed rgba(255,255,255,.1);
        `};
    }
    @media only screen and (min-width: 960px) {
        width:25%;
        margin-bottom:20px;
        align-items: flex-start;
    }
    &.${activeClassName} {
      border-radius: 12px;
      font-weight: bold;
      color: ${({ theme }) => theme.text1};
    }
  
    :hover,
    :focus {
      color: ${({ theme }) => darken(0.1, theme.text1)};
    }
  
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    //   margin: 0 8px;
    `}
  `
const DappItemImg = styled.img`
  width:40px;
  height:40px;
  border-radius: 50%;
`
const DappItemTxt = styled.span`
  font-size:12px;
  margin-top:8px;
  word-break: break-all;
  white-space: wrap;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  width:92%;
  color: ${({ theme }) => theme.text2};
  @media only screen and (min-width: 960px) {
    
 }
`
const DappItemName = styled.span`
  font-size:14px;
  font-weight:600;
  color: ${({ theme }) => theme.text1};
`
const DappItemInfo = styled.div`
  padding-left:12px;
  width:100%;
  flex:1;
  display:flex;
  box-sizing: border-box;
  flex-direction: column;
`


export default function Find(){
    const { t } = useTranslation()
    const [ hotList, setHotList] = useState([]);
    const [dappList,setDappList] = useState([]);
    const getData = async ()=>{
        return await fetch('dapplist.json').then(d=>d.json()).then(dd=>dd)
    }
    useEffect(() => {
        getData().then(obj=>{
            // console.log(obj)
            setHotList(obj.hot)
            setDappList(obj.dapp)
        })
    }, []);
    
    return (
        <FindWrapper>
            <BannerImg src={banner}/>
            <FindMain>
                <FindTitle>{t('findtj')}</FindTitle>
                <FindList>
                    {
                        hotList.map((item,index)=>{
                            return (
                                <StyledExternalLink id={`stake-nav-link`} href={item['website']} key={index}>
                                    <ItemImg src={item['logoURI']} />
                                    <ItemTxt>{item['name']}</ItemTxt>
                                </StyledExternalLink>
                            )
                        })
                        
                    }
                </FindList>
                <FindTitle>{t('findall')} DApps</FindTitle>
                <FindDappList>
                    {
                        dappList.map((item,index)=>{
                            return (
                                <StyledGrowExternalLink id={`stake-nav-link`} href={item['website']} key={index}>
                                    <DappItemImg src={item['logoURI']} />
                                    <DappItemInfo>
                                        <DappItemName>{item['name']}</DappItemName>
                                        <DappItemTxt>{item['intro']}</DappItemTxt>
                                    </DappItemInfo>
                                </StyledGrowExternalLink>
                            )
                        })
                    }
                </FindDappList>
            </FindMain>
           
        </FindWrapper>
    )
}
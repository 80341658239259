import { ChainId, JSBI, Percent, Token, WETH } from 'goswap-sdk'
import { AbstractConnector } from '@web3-react/abstract-connector'

import { fortmatic, injected, portis, walletconnect, walletlink } from '../connectors'

// 路由合约地址
export const ROUTER_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '',
  [ChainId.KTOTEST]: '0x069AB1EdA5537525b3e0c1E003EedB5F1e90006d',//keepswap 更新
  [ChainId.KTOMAIN]: '0xb544f5Cef94fE0B2C3BB3a4886ea8918Bbe444f5',//keepswap 更新 1:0x42D99c326bBc8AEd650baeB20E638c6dfCB0e086 2:0xD0EF3120E50Bb49fFEE670886E07860Fc5247d5E
}

// 0 地址
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const USDT = new Token(ChainId.KTOTEST, '0x289d44257A776edF7dAD62D8D8851838DA81c36B', 18, 'USDT', 'USDT')
export const DAI = new Token(ChainId.KTOTEST, '0x1AE948EB906fF65F44991Bdc6D35670246B8f964', 18, 'DAI', 'DAI')
export const USDC = new Token(ChainId.KTOTEST, '0xa01E9ce9d185Cd22244C1Bb21903eb30ecF65EF4', 18, 'USDC', 'USDC')

export const AMPL = new Token(ChainId.KTOTEST, '0x447c7b2620D1CC5ac056c1Ec568456de35b64edA', 18, 'AMPL', 'Ampleforth')
export const WBTC = new Token(ChainId.KTOTEST, '0x28E44f86C25f58Bc8D8dD45bF0873CD4AE363e77', 18, 'WBTC', 'Wrapped BTC')

// TODO this is only approximate, it's actually based on blocks
export const PROPOSAL_LENGTH_IN_DAYS = 7

// 治理地址，貌似没用
export const GOVERNANCE_ADDRESS = '0x5e4be8Bc9637f0EAA1A755019e06A68ce081D58F'

// 貌似没用
const UNI_ADDRESS = '0xc7fD9aE2cf8542D71186877e21107E1F3A0b55ef'
export const UNI: { [chainId in ChainId]: Token } = {
  [ChainId.MAINNET]: new Token(ChainId.MAINNET, UNI_ADDRESS, 18, 'UNI', 'Uniswap'),
  [ChainId.KTOTEST]: new Token(
    ChainId.KTOTEST,
    '0x4957ae3e7238e3EB9b377854f8c990cbABFCcFFC',
    11,
    'WKTO',
    'Unisave'
  ),
  [ChainId.KTOMAIN]: new Token(
    ChainId.KTOMAIN,
    '0x3850D46C0442A86AaDd41D99f635e316c8788269',
    11,
    'WKTO',
    'Unisave'
  ),
}

// TODO: specify merkle distributor for mainnet
export const MERKLE_DISTRIBUTOR_ADDRESS: { [chainId in ChainId]?: string } = {
  [ChainId.MAINNET]: '0x090D4613473dEE047c3f2706764f49E0821D256e'
}
export const K_USDT = new Token(
  ChainId.KTOMAIN,
  '0xa6Ea066Bae96432414ac62818e62E2ECAa570dE3',
  18,
  'USDT',
  'Tether USD'
)

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.KTOTEST]: [WETH[ChainId.KTOTEST]],
  [ChainId.KTOMAIN]: [WETH[ChainId.KTOMAIN]],
}

// 用于构建交易的中介对
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.KTOMAIN]: [...WETH_ONLY[ChainId.KTOMAIN],K_USDT],
}

/**
 * 有些令牌只能通过某些对进行交换，因此我们重写考虑这些令牌的碱基列表
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  // [ChainId.MAINNET]: {
  //   [AMPL.address]: [DAI, WETH[ChainId.MAINNET]]
  // }
}

// 用于添加流动性时在默认列表中显示
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT],
  [ChainId.KTOTEST]: [USDT, DAI, USDC, AMPL, WBTC],
  [ChainId.KTOMAIN]: [...WETH_ONLY[ChainId.KTOMAIN],K_USDT],

}

// 用于构造前端默认考虑的所有对的列表
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT],
  [ChainId.KTOTEST]: [...WETH_ONLY[ChainId.KTOTEST], USDT, DAI, USDC, AMPL, WBTC],
  [ChainId.KTOMAIN]: [...WETH_ONLY[ChainId.KTOMAIN], K_USDT],
}

// 固定的pair
export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.KTOTEST]: [
    [
      new Token(ChainId.KTOTEST, '0x289d44257A776edF7dAD62D8D8851838DA81c36B', 18, 'USDT', 'Tether USD'),
      new Token(ChainId.KTOTEST, '0xa01E9ce9d185Cd22244C1Bb21903eb30ecF65EF4', 18, 'USDC', 'USDC'),
    ]
  ],
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  },
  COINBASE_LINK: {
    name: 'Open in Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Open in Coinbase Wallet app.',
    href: 'https://go.cb-w.com/mtUDhEZPy1',
    color: '#315CF5',
    mobile: true,
    mobileOnly: true
  },
  FORTMATIC: {
    connector: fortmatic,
    name: 'Fortmatic',
    iconName: 'fortmaticIcon.png',
    description: 'Login using Fortmatic hosted wallet',
    href: null,
    color: '#6748FF',
    mobile: true
  },
  Portis: {
    connector: portis,
    name: 'Portis',
    iconName: 'portisIcon.png',
    description: 'Login using Portis hosted wallet',
    href: null,
    color: '#4A6C9B',
    mobile: true
  }
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20

export const BIG_INT_ZERO = JSBI.BigInt(0)

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much BNB so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
export const BETTER_TRADE_LINK_THRESHOLD = new Percent(JSBI.BigInt(75), JSBI.BigInt(10000))
